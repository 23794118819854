import React from "react";
import "./contact.scss";

function ContactPage() {
  return (
    <>
      <div className="col-12 h-100 py-5">
        <div className="container h-100">
          <div className="col-8 mx-auto bg-light">
            <div className="col-12 p-5">
              <h1 className="font-xxl app-font-rg text-center">Say Hi!</h1>
              <div className="col-12 d-flex flex-row flex-nowrap align-content-center mx-auto pb-4">
                <div className="col-12 p-x2">
                  <label className="cstm_lbl col-12 py-2">
                    Name<sup> *</sup>
                  </label>
                  <input
                    className="form-control cstm_input"
                    name="fName"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-12 d-flex flex-row flex-nowrap align-content-center mx-auto pb-4">
                <div className="col-12">
                  <label className="cstm_lbl col-12 py-2">
                    Email<sup> *</sup>
                  </label>
                  <input
                    className="form-control cstm_input"
                    name="email"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-12 d-flex flex-row flex-nowrap align-content-center mx-auto pb-4">
                <div className="col-12">
                  <label className="cstm_lbl col-12 py-2">
                    Subject<sup> *</sup>
                  </label>
                  <input
                    className="form-control cstm_input"
                    name="subject"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-12 d-flex flex-row flex-nowrap align-content-center mx-auto pb-4">
                <div className="col-12">
                  <label className="cstm_lbl col-12 py-2">
                    Message<sup> *</sup>
                  </label>
                  <textarea
                    className="form-control cstm_input"
                    name="msg"
                    type="text"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="col-12 py-4 text-center">
                <button
                  className="btn btn-outline-dark cstm_btn_dark"
                  value="Submit"
                  title="Click to Send your Message"
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
