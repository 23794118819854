import React from "react";

import "./dc.scss";
import dc1 from "../../../../../assets/images/dc/4.png";
import dc2 from "../../../../../assets/images/dc/8.png";
import dc3 from "../../../../../assets/images/dc/1.png";
import dc4 from "../../../../../assets/images/dc/3.png";
import dc5 from "../../../../../assets/images/dc/2.png";
import dc6 from "../../../../../assets/images/dc/8.png";
import dc7 from "../../../../../assets/images/dc/10.png";
import dc8 from "../../../../../assets/images/dc/6.png";
import dc9 from "../../../../../assets/images/dc/5.png";

function DigitalClassrooms() {
  return (
    <div>
      <div className="bg-white w-100 position-relative dcBnr">
        <div className="bnr_section w-100 bg-transparent d-inline-block">
          <span className="dc_bnr_img"></span>
        </div>
        <div className="floating_container">
          <span className="position-absolute d-inline-block bnr_floating_ttl mx-auto">
            <span className="floating_text d-inline-block font-xxxxl text-white">
              Digital Classroom
            </span>
          </span>
        </div>
      </div>
      <div className="bg-white w-100 pt-5 pb-3">
        <div className="container">
          <div className="w-100 d-flex flex-row">
            <div className="col-4 px-2 d-flex align-items-start">
              <div className="d-flex h-100 w-100 pt-5">
                <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                  Overview
                </span>
              </div>
            </div>
            <div className="col-8 px-2">
              <p className="pb-3 font-m app-font-rg">
                China and Italy is the most revenue business in WSE and we have
                franchise to help students and business people to learn and
                speak English. The goal of this particular userinterview was to
                identify any usability issues with the latest release and
                determine the critical features to prioritise for the next
                iteration of the product.
              </p>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Business Goals</h4>
                <p className="pb-2 font-m app-font-rg">
                  WSE is a China based learning english company that connects
                  businesses to their customers through a digitalization of the
                  learning experience, both teachers and students are able to
                  improve their skills, with a common goal: to create a more
                  engaging and effective process of speaking english. WSE
                  provide online and offline service to helps student learn
                  english anywhere. Digital classroom give a hands to the
                  students and improve their learning and speaking.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">User Goals</h4>
                <p className="pb-2 font-m app-font-rg">
                  To learn english within less peroid of time and easy way of
                  speaking english with support of good teachers in online.
                </p>
              </div>
              <div className="w-100 pb-3">
                <img className="img-fluid" src={dc1} alt="section-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100 pt-5 pb-3">
        <div className="container">
          <div className="w-100 d-flex flex-row">
            <div className="col-4 px-2 d-flex align-items-start">
              <div className="d-flex h-100 w-100 pt-5">
                <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold w-75">
                  Exploration & Research
                </span>
              </div>
            </div>
            <div className="col-8 px-2">
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">The problem</h4>
                <p className="pb-2 font-m app-font-rg">
                  In 2019 November, we introduced an adding four students to
                  eight students in one group class session. Privilege Sharing
                  and breakout session features are how to help teachers and
                  students individualized content. Teachers could assign any
                  item of content (exercises, videos, sessions, quizzes) with a
                  gruoup of of students. Previously, the breakout session and
                  privilege sharing not availabe in our digital classroom and
                  testing with these features to the real users to get results.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Process</h4>
                <p className="pb-2 font-m app-font-rg">
                  I have been travel to myanmar to condust usabliity test the
                  features along with my developer team. To understand student
                  and staff pain points during the encounter and other class
                  session and how digitalclass room hepls to achieve our
                  students. some key achievements of which I have listed below:
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Implemented a design process:</h4>
                <p className="pb-2 font-m app-font-rg">
                  This has helped our team establish more structure to how we
                  conduct our work and allow other teams to gain visibility
                  across our upcoming sprints. Since we following agile
                  metholidy to rapdily accomoplish busienss and user goals.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Improved usability across the platform:</h4>
                <p className="pb-2 font-m app-font-rg">
                  No usability tests were conducted before by our design and am
                  the fortune one to travel along with development team to test
                  the features. conducting UX research and Usability testing on
                  all projects.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Establishing a design system: </h4>
                <p className="pb-2 font-m app-font-rg">
                  This has helped to maintain consistency in the look and feel
                  across different parts of the platform. Since we having our
                  design kit for WSE. This has helped the and Product teams to
                  understand how and why we choose to implement certain
                  components over others.
                </p>
              </div>
              <div className="w-100 pb-3">
                <img className="img-fluid" src={dc2} alt="section-1" />
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Initial explorations & research</h4>
                <p className="font-m app-font-rg">
                  I conducted research interviews with our primary users
                  (Students) and second users (teachers) to uncover any pain
                  points that they were experiencing with the our new release.
                </p>
                <p className="font-m app-font-rg">My research encompassed:</p>
                <p className="font-m app-font-rg">
                  * Understanding the user goals and needs
                </p>
                <p className="font-m app-font-rg">
                  * Uncovering pain points with the existing user journey
                </p>
                <p className="font-m app-font-rg">
                  * Determining the success of the tasks measured
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">User Interviews</h4>
                <p className="font-m app-font-rg">
                  The perspective of users and theri understing of digital
                  classroom, I had speak with students directly and ask them
                  about their studying experiences and habits. I used the
                  discussion guide questions to conduct interviews with stuents
                  and staff to know how digital classroom help easily to acheive
                  their goals. I have select certain students and staff with
                  question to know their pain points. staffs are supercool
                  shared their experience. Initially started up with basic
                  question to understand how they are interested in Digital
                  learning.
                </p>
              </div>
              <div className="w-100 pb-3">
                <img
                  className="img-fluid"
                  src={dc3}
                  alt="section-1"
                  height="auto"
                />
              </div>
              <div className="w-100 py-3">
                <h4 className="font-xxl app-font-bold mb-3">Some of the Example Questions:</h4>
                <p className="font-m app-font-rg">
                  1. How are students’ mobile devices integrated pedagogically
                  into a Digital classroom classroom?
                </p>
                <p className="font-m app-font-rg">
                  2. How do students experience learning in a Digital classroom?
                </p>
                <p className="font-m app-font-rg">
                  3. What are the perspectives of teachers about student
                  confidence and engagement in a Digital classroom?
                </p>
                <p className="font-m app-font-rg">
                  4.How coaches (Personal Tutor) can be involved in the online
                  platform?
                </p>
                <p className="font-m app-font-rg">
                  5.How Personal Coaches caring is mitigated in Myanmar?
                </p>
                <p className="font-m app-font-rg">
                  6.How the student will know the EN is booked? Currently the
                  coaches will call them/Message them in personal to track and
                  notify about booked classes. And few more questions we have
                  asked them to know more.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">Persona</h4>
                <p className="font-m app-font-rg">
                  Narrowing down our findings from user interviews I have
                  created a persona that embodied the pain points,
                  characteristics and motivations of our target user.
                </p>
              </div>
              <div className="w-100 pb-3">
                <img
                  className="img-fluid"
                  src={dc4}
                  alt="section-1"
                  height="auto"
                />
              </div>
              <div className="w-100 py-3 d-flex flex-row">
                <div className="col-6">
                  <h4 className="font-xxl app-font-bold mb-3">Interview to the teachers of digital classroom</h4>
                  <p className="font-m app-font-rg">
                    I had interview with teachers as well to understand how to
                    motivate to engage the students. So eco friendly and
                    interacting with teachers. Teaching facilities of the
                    digital classroom the teacher uses and how often, and what
                    facilities and devices the students use and how often. The
                    teacher explains that the classroom has been equipped with
                    the Multimedia Interactive Whiteboard session, Since the
                    beginning of the experience. This section of the interview
                    dealt with teaching activities in the digital classroom. The
                    teacher was asked how she prepares her teaching activities,
                    what the main changes are with respect to traditional
                    lessons, what teaching methods and tools are used in the
                    digital classroom, how she makes use of the time available,
                    how the teacher's role changes in the digital classroom. The
                    teacher observes that every class has its own
                    characteristics. This class is particularly vivacious and
                    anarchic, and this characteristic has been strengthened by
                    digital facilities.
                  </p>
                </div>
                <div className="col-6 d-flex align-items-center text-center">
                  <img className="mx-auto" width="90%" src={dc5} alt="section-5" />
                </div>
              </div>
              <div className="w-100 pb-3">
                <img
                  className="img-fluid"
                  src={dc6}
                  alt="section-1"
                  height="auto"
                />
              </div>
              <div className="w-100 py-3">
                <p className="font-m app-font-rg">
                  Few questions i have asked teachers to understand how they are
                  interact with digital classroom.
                </p>
                <p className="font-m app-font-rg">
                  1. Teachers want to see individual student effort in Digital
                  classroom. If they did poorly on an exercise, did they watch a
                  related video or read a related article? Did they use any
                  hints? Have they improved since last week?
                </p>
                <p className="font-m app-font-rg">
                  2. Teachers want to identify groups of students.?
                </p>
                <p className="font-m app-font-rg">
                  3. Which students are ahead? Which students have gaps in
                  learning? Many teachers pull small groups aside to do a
                  mini-lesson during class.
                </p>
              </div>
              <div className="w-100 py-3 d-flex flex-row">
                <div className="col-12">
                  <h4 className="font-xxl app-font-bold mb-4">
                    Mobile screens
                  </h4>
                  <div className="col-12 d-flex flex-row flex-nowrap">
                    <div className="col-4 px-3">
                      <img src={dc7} alt="dc7" className="img-fluid" />
                    </div>
                    <div className="col-4 px-3">
                      <img src={dc8} alt="dc8" className="img-fluid" />
                    </div>
                    <div className="col-4 px-3">
                      <img src={dc9} alt="dc9" className="img-fluid" />
                    </div>
                  </div>
                  <p className="font-m app-font-rg py-4 text-primary text-center">
                    DIgital classroom availabale in both iOS and Android.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100 pt-5 pb-3">
        <div className="container">
          <div className="w-100 d-flex flex-row">
            <div className="col-4 px-2 d-flex align-items-start">
              <div className="d-flex h-100 w-100 pt-5">
                <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                  Outcomes
                </span>
              </div>
            </div>
            <div className="col-8 px-2">
              <h4 className="font-xxl app-font-bold mb-3">Usability Testing Feedback</h4>
              <p className="pb-2 font-m app-font-rg">
                I have tested the designs with both teachers and students
                working in other field(Users). I interviews 8 different studnets
                and 4 teachers pain point and narrow down. 4 teachers responded
                out of the myanmar center staff at yangon. In general, they
                loved this new feature because they could see all the
                information at once. Based on the feedback from round 1, I have
                decided to implement a whiteroom breakout session and assessment
                filter to help teachers identify gaps easier. Some teachers
                assign videos, articles, and exercises, so they want to be able
                to filter to see only exercises for a week at a time. Some of
                the major findings has to enhance form student side as a request
                and i have noted those points and monitior to the managemnent.
              </p>
              <p className="font-m app-font-rg">
                1. One to one computing with students seated in traditional rows
              </p>
              <p className="font-m app-font-rg">2. Paired seating</p>
              <p className="font-m app-font-rg">3. Small group seating</p>
              <p className="font-m app-font-rg">4. Multi-screen classrooms</p>
              <p className="font-m app-font-rg">5. Mobile learning</p>
              <div className="w-100 py-3">
                <h4 className="font-xxl app-font-bold mb-3">Results and takeaways</h4>
                <p className="pb-2 font-m app-font-rg">
                  After conducting many user interviews and surveys, we felt
                  really confident about launching this to 100% of our users
                  across the world. We changed a few small things before
                  launching including adding those points and enhanced in design
                  for next release for more usability. This feature was very
                  successful and increased the revence 35%. The number of
                  monthly very active learners with a teacher increased by 20%.
                  to 68% of monthly active teachers. Our original goal was
                  70%revenue. We want to continue exploring ways to make digital
                  room class for better for non native speakers but this would
                  involve a major strategy shift on many teams. In the future,
                  we're going to explore more ways of incorporating classroom
                  activity with suppor of digital technologies,
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalClassrooms;
