import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.scss";

let allMenus = [
  {
    isActive: false,
    menuName: "Work",
    paths: ["work", "ent-mng", "ds", "dc", "ag", "iqvia"],
    mpath: "work",
  },
  {
    isActive: false,
    menuName: "About",
    paths: ["about"],
    mpath: "about",
  },
  {
    isActive: false,
    menuName: "Contact",
    paths: ["contact"],
    mpath: "contact",
  },
];

function SideBar(props) {
  let [menus, setMenus] = useState(allMenus);
  const location = useLocation();

  const assignMenu = useCallback(() => {
    setMenus((prevMenu)=> prevMenu.map((e) => {
      e.isActive = false;
      if (e.paths.includes(location.pathname.replace("/", ""))) {
        e.isActive = true;
      }
      return e;
    }));
  }, [location]);

  useEffect(() => {
    assignMenu()
  }, [assignMenu]);

  return (
    <div className={"h-100 w-100 sidebar_color app-bg-white"}>
      <div
        className={
          "d-flex flex-row h-100 justify-content-center align-items-center container"
        }
      >
        <div className={"flex-grow-1"}>
          <h5 className="app-font_logo app_logo"> JF </h5>
        </div>
        <div
          className={`sideBarColumn flex-grow-1 d-flex flex-row justify-content-end align-items-center`}
        >
          {menus?.map((e, i) => {
            return (
              <div key={i} className={"py-4 px-2"}>
                <NavLink
                  to={e?.mpath}
                  className={`menuItem app-color-dark crsr_pntr fs-5 ${
                    e?.isActive ? "active app-font-Md" : "app-font-Rg"
                  }`}
                >
                  {e?.menuName}
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
