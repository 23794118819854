import React from "react";
import "./em.scss";

import p1 from "../../../../../assets/images/ent-mng/10.png";
import p2 from "../../../../../assets/images/ent-mng/14.png";
import p3 from "../../../../../assets/images/ent-mng/16.png";
import p4 from "../../../../../assets/images/ent-mng/18.png";
import p5 from "../../../../../assets/images/ent-mng/19.png";
import p6 from "../../../../../assets/images/ent-mng/17.png";
import p7 from "../../../../../assets/images/ent-mng/22.png";
import p8 from "../../../../../assets/images/ent-mng/21.png";
import p9 from "../../../../../assets/images/ent-mng/20.png";
import p10 from "../../../../../assets/images/ent-mng/15.png";
import p11 from "../../../../../assets/images/ent-mng/12.png";
import p12 from "../../../../../assets/images/ent-mng/11.png";
import p13 from "../../../../../assets/images/ent-mng/8.png";
import p14 from "../../../../../assets/images/ent-mng/7.png";
import p15 from "../../../../../assets/images/ent-mng/5.png";
import p16 from "../../../../../assets/images/ent-mng/4.png";
import p17 from "../../../../../assets/images/ent-mng/13.png";
import p18 from "../../../../../assets/images/ent-mng/1.png";
import p19 from "../../../../../assets/images/2.png";

function EntityManagement() {
  return (
    <>
      <div>
        <div className="w-100 app-bg-blue emBg">
          <div className="container h-100 py-5" style={{ maxWidth: "1112px" }}>
            <div className="col-12 d-flex flex-row h-100 align-items-center">
              <div className="col-6">
                <h1 className="app-font-bold font-xxxxl mb-3">
                  {" "}
                  Wolterskluwer
                </h1>
                <h1 className="font-xxl app-font-smBold">
                  Revamping the Entity Management
                </h1>
                <h4 className="mt-4 w-100 font-l app-font-md">
                  UX UI DESIGN | UX RESEARCH
                </h4>
              </div>
              <div className={"col-6"}>
                <img src={p19} className="img-fluid" alt="em_banner" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pt-5 d-flex flex-row flex-nowrap">
              <div className="col-8">
                <div className="col-10 mr-auto">
                  <h1 className="font-xxl app-font-bold">
                    What is Wolterskluwer?
                  </h1>
                  <p className="mt-3 font-m app-font-rg">
                    Wolters Kluwer is a global provider of professional
                    information, software solutions, and services for
                    accountants, lawyers, and tax, finance, audit, risk,
                    compliance, and regulatory sectors. Our range of expert
                    solutions combine deep domain knowledge with advanced
                    technology and services to deliver better outcomes,
                    analytics, and improved productivity for our customers.
                  </p>
                </div>
                <div className="col-10 mr-auto py-5">
                  <h1 className="font-xxl app-font-bold">
                    About Entity Management
                  </h1>
                  <p className="mt-3 font-m app-font-rg">
                    A best-in-class centralized platform that enables the
                    efficient and secure handling of all your legal entity
                    data—at the local, state, national and global level. Our
                    entity management solutions are developed and supported by a
                    team of experts with specialized knowledge and unmatched
                    experience, so you get the control you need to manage and
                    maintain your most crucial entity data.
                  </p>
                </div>
              </div>
              <div className="col-4 d-flex flex-column">
                <div className="col-8 ml-auto">
                  <h1 className="font-xxl app-font-bold">Client</h1>
                  <p className="mt-3 font-m app-font-rg">WoltersKluwer</p>
                </div>
                <div className="col-8 pt-4 ml-auto">
                  <h1 className="font-xxl app-font-bold">My Role</h1>
                  <p className="mt-3 font-m app-font-rg">- UX Research</p>
                  <p className="mt-3 font-m app-font-rg">- Design Guidelines</p>
                  <p className="mt-3 font-m app-font-rg">
                    - Portal Rewamp Design
                  </p>
                  <p className="mt-3 font-m app-font-rg">- User Testing</p>
                </div>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">User Pain Points</h1>
                <p className="font-m app-font-rg">
                  At the onset of this project, I knew that the website already
                  appeared outdated with old content; however, what my team
                  uncovered during the design and strategy process to improve
                  user experience:
                </p>
                <p className="font-m app-font-rg">- More Paper works</p>
                <p className="font-m app-font-rg">
                  - User Experience is not good
                </p>
                <p className="font-m app-font-rg">
                  - Poor communication channels for documents submission
                </p>
                <p className="font-m app-font-rg">
                  - Poor bounce rate(Salesteam CT)
                </p>
                <p className="font-m app-font-rg">
                  - Many users don’t understand the data in a bunch of written
                  formats.
                </p>
                <p className="font-m app-font-rg">
                  - Old services. In fact, a Google Analytics and heatmap report
                  revealed how users interacted with the site leading to almost
                  zero leads coming in and complicated to understand for a
                  non-marketer person(Sales, Finance).
                </p>
                <p className="font-m app-font-rg">
                  - Errors in entity compliance can be damaging to a company and
                  its brand. Missed deadlines, filings or payments can result in
                  lost time and money, governmental penalties and bad public
                  relations.
                </p>
              </div>
              <div className="col-12 py-5">
                <h1 className="font-xxl app-font-bold">Goal Strategy </h1>
                <p className="font-m app-font-rg pt-2">
                  When I started working on the project, I realized that the
                  previous site was outdated in both content and design. I took
                  the challenge to create not only a new site but a new
                  experience with a story to tell for the users. One of the
                  biggest challenges was to find a balance creating something
                  modern and credible to meet the serious needs of our clients:
                  Small business, Corporate, law firms , Create a sleek and
                  intuitive site to generate leads, keeping in mind that the
                  design needs to reflect new core values.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 app-bg-blue py-5">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto">
              <h3 className="font-xxl app-font-bold pb-2">Work Flow</h3>
              <div className="col-12 d-flex flex-row flex-wrap">
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Product Brief</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Competitor Analysis</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Customer Journeymap</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">User interviews & Survey</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Information Architecture</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Wireframe & Final Solutions</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Outcome Results</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Competitor Analysis</h1>
                <p className="font-m app-font-rg">
                  Before taking up user interviews,
                </p>
                <p className="font-m app-font-rg">- More Paper works</p>
                <p className="font-m app-font-rg">
                  - I wanted to understand the requirements and problems in the
                  field by looking at what the competitors were doing.
                </p>
                <p className="font-m app-font-rg">
                  - I checked out several website and and their competitors
                  portal help of SWOT analysis, checked out their reviews and
                  interface, services. Exploring each of their websites and
                  their services, I evaluated the strengths and weaknesses of
                  each to see how we could fill in any gaps moving forward.
                </p>
                <p className="font-m app-font-rg">
                  - To conclude, we required case cards with simple information
                  and a good search engine to proceed up ahead.
                </p>
              </div>
              <div className="col-12 d-flex flex-row flex-nowrap py-5">
                <img src={p1} className="img-fluid" alt="comps" />
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Customer Journey Map</h1>
                <p className="font-m app-font-rg pt-2">
                  Improve customer experience at every touchpoint with an
                  accurate snapshot of all the ways customers interact with our
                  brand. The most common pain point. “It was really eye-opening
                  to hear about the frustration caused by the gap between
                  expectation and reality,” says Kevin. Customers believed it
                  had the potential to be helpful but found it difficult to
                  schedule and didn’t know what to expect. As a result, they
                  came up with their own ideas about what it would include, then
                  came away disappointed when it didn’t. Relatively high ratings
                  even in areas that caused some friction indicated that despite
                  room for improvement, customers were generally satisfied with
                  their decision to take up subscription plans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100 py-5">
          <div className="container py-5">
            <img src={p2} className="img-fluid" alt="cst-jrny" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Persona</h1>
                <p className="font-m app-font-rg pt-2">
                  Based on hearing to different stories and comments, I came up
                  with a User Persona, I created the profiles, their mission
                  statement, end goals and frustrations to better understand the
                  user. To make sure that my decisions moving forward in the
                  process are user-centered, I wanted to have a clear
                  understanding of who Wolterskluwer users are. Using what I
                  learned from patterns from my empathy map, I created a user
                  persona to represent who I will be designing for Meet -
                  Robert, Lisa, Christina, David.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-6 p-4">
                <img className="img-fluid" src={p3} alt="pr1" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p4} alt="pr2" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p5} alt="pr3" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p6} alt="pr4" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">
                  User Interview and Survey
                </h1>
                <p className="font-m app-font-rg pt-2">
                  To dig deeper into the problems faced by the small
                  business,corporate, Law firms and I decided to take personal
                  interviews on remote due to pandemic situation. Out of the
                  four small business, two corporate, and one law firms I
                  interviewed on call, some with the help of my client. I have
                  prepared the userinterview questions based on the type of user
                  business. For the suvey we have listed down the few question
                  samples for moderate testing with the user. I gathered 4
                  participants (2 women and 2 men between the ages of 30 and
                  65), who I interviewed via Zoom and Google Meet.
                </p>
                <p className="font-m app-font-rg pt-2">
                  <strong>Survey Tool: Userzoom, Google Meet</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-secondary-green w-100 py-5">
          <div className="container py-3" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-3 p-2">
                <img className="img-fluid" src={p7} alt="pr7" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p8} alt="pr8" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p7} alt="pr9" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p8} alt="pr10" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Product Roadmap</h1>
                <p className="font-m app-font-rg pt-2">
                  Based on the goals I defined, I started making decisions on
                  what solutions we would prioritize and implement in order to
                  successfully and effectively meet those goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p9} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Sitemap</h1>
                <p className="font-m app-font-rg pt-2">
                  In order to keep the information architecture structure of the
                  entity management in the right manner, I have visited the
                  portal and listed down the major painpoints and reconstructe
                  the inforation architecture based on user interviews. Based on
                  the inputs received and after segregating the different tasks
                  which helped me to further iterate the prototypes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p10} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Wireframes</h1>
                <p className="font-m app-font-rg pt-2">
                  Taking the Low-fi wireframe sketches, I digitized them on
                  Sketch and added just enough information for users to be able
                  to navigate through other entity pages and complete tasks, I
                  would present to them during usability testing for initial
                  level of sprint. These mid-fidelity wireframes would help my
                  focus on what needed to be improved in terms of the
                  functionality of my design. I also created tablet and mobile
                  versions to make sure that the design is responsive and
                  effective across the different device screens that users would
                  be accessing the website on.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p11} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="app-bg-white w-100 py-4">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p12} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <h1 className="font-xxl app-font-bold">New Visual Design</h1>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-6 p-4">
                <img className="img-fluid" src={p13} alt="pr13" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p14} alt="pr14" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p15} alt="pr15" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p16} alt="pr16" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p17} alt="pr17" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p18} alt="pr18" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-3">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">
                  Conduct Usability Testing
                </h1>
                <p className="font-m app-font-rg py-2">
                  I then conducted both in-person and remote usability testing
                  with 5 participants, and created transcripts for each
                  participant based on my observation of their interaction with
                  the prototype. I jot down their mistakes, slips, and
                  confusions they expressed in the process. This transcript is a
                  perfect raw material for summarizing the patterns of user’s
                  interaction with the prototype.
                </p>
                <h1 className="font-xl app-font-bold pt-3">
                  Design objectives:
                </h1>
                <p className="font-m app-font-rg">
                  Test the ease of navigation Entity pages.
                </p>
                <p className="font-m app-font-rg">
                  Test how well the interface promotes a feeling of calm and
                  trust for the user.
                </p>
                <p className="font-m app-font-rg">
                  Test how easily the user can complete an entity documents to
                  submit through online.
                </p>
                <p className="font-m app-font-rg">
                  Assess whether components are well placed to promote easy task
                  completion and site navigation.
                </p>
                <p className="font-m app-font-rg">
                  I designed my user flow so that users could move in a few
                  different ways from the Dashbaord all the way through the
                  quote and contracts.
                </p>
                <p className="font-m app-font-rg">
                  I posted my usability test link clicable prototye on userzoom,
                  with the request for customer and CT service team members to
                  test both internal and external view.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Outcome</h1>
                <h4 className="font-m app-font-rg py-1">
                  As a result of the new user experience and flow of the portal,
                  the conversion rate increased by 7.12%. User moved paper works
                  to digitalised.
                </h4>
                <p className="font-m app-font-rg">
                  - My client gave me changes almost every day, in the main
                  structure and the idea of the website itself which made it
                  complex to come to a solution.
                </p>
                <p className="font-m app-font-rg">
                  - Try to empathize not only with your customers but also with
                  your client/project managers to think like what is in their
                  mind, what actually are they looking out.
                </p>
                <p className="font-m app-font-rg py-2">
                  <strong>
                    “Most importantly, empathize with the problem to find the
                    right solution. If possible, try to have first hand
                    experience with the problem itself.”
                  </strong>
                </p>
                <p className="font-m app-font-rg">
                  Understand the person who you interact with for project
                  details or reporting, empathize with them, empathize with the
                  users and research deeply about the field you are working in.
                  This helps you create a really great user experience that can
                  be implemented.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EntityManagement;
