import React from "react";
import { Route, Routes } from "react-router-dom";
import $ from "jquery";
import "../../assets/css/custom.scss";
import "./full-layout.scss";
import SideBar from "../sidebar/sidebar";

import HomePage from "../pages/home/home";
// import WorkPage from "../pages/work/work";
import AboutPage from "../pages/about/about";
import ContactPage from "../pages/contact/contact";
import DigitalClassrooms from "../pages/work/projects/dc/dc";
import EntityManagement from "../pages/work/projects/em/em";
import DesignSystems from "../pages/work/projects/ds/ds";
import AvignaProperties from "../pages/work/projects/ag/ag";

import ScrollToTop from "../../scrolltotop";
import Footer from "../pages/footer/footer";
import Iqvia from "../pages/work/projects/iqvia/iqvia";
import { ToasterService } from "../services/toaster-service/toaster";

class Layout extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", (event) => {
      setTimeout(() => {
        this.handleScroll(null);
      }, 0);
    });
  }

  handleScroll = () => {
    if (window.pageYOffset > 100) $(".navbar_style").addClass("nav_shadow");
    else $(".navbar_style").removeClass("nav_shadow");
  };

  openToaster = (type) => {
    ToasterService.SetToast(type, <>Hello Toaster</>);
  };

  render() {
    return (
      <>
        <div className={"min-h-100 d-flex flex-column col"}>
          <ScrollToTop>
            <div
              className={"w-100 navbar_style"}
              style={{ position: "fixed", zIndex: 1000 }}
            >
              <SideBar />
            </div>
            <div className={"w-100"} style={{ paddingTop: "82px" }}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/work" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/dc" element={<DigitalClassrooms />} />
                <Route path="/ent-mng" element={<EntityManagement />} />
                <Route path="/ds" element={<DesignSystems />} />
                <Route path="/iqvia" element={<Iqvia />} />
                <Route path="/avigna" element={<AvignaProperties />} />
              </Routes>
            </div>
            <div className={"w-100"}>
              <Footer />
            </div>
            {/* <div className="text-center py-5">
              <button type="button" onClick={() => this.openToaster('success')}>
                Open Success Toaster
              </button>
              <button type="button" onClick={() => this.openToaster('info')}>
                Open Info Toaster
              </button>
              <button type="button" onClick={() => this.openToaster('warn')}>
                Open Warn Toaster
              </button>
              <button type="button" onClick={() => this.openToaster('error')}>
                Open Error Toaster
              </button>
            </div>
            <div id="app-toaster"></div> */}
          </ScrollToTop>
        </div>
      </>
    );
  }
}

export default Layout;
