import React from "react";
import "./about.scss";

import jf from "../../../assets/images/jf.png";

function AboutPage() {
  return (
    <div className="w-100 bg-white h-100">
      <div className="container h-100 py-5">
        <div className="col-12 h-100 d-flex flex-row align-items-center justify-content-center w-100">
          <div className="col-6">
            <h2 className="font-xxxl app-font-bold mb-4">About me,</h2>
            <p className="font-m app-font-rg">
              Hi there, I’m Jona. I am a Product designer based in India. I
              create thoughtful experiences that are built for everyone to use
              and enjoy. All in all, I believe that, as a designer, I have a
              mission to genuinely care for the people I design for. Started my
              career as a freelance designer and I am currently working as a
              Lead UX designer in Virtusa. My experiences in these roles have
              sharpened my skills in digital design, working in a fast paced,
              high-pressure environment, empathizing with people, doing research
              and analysis, identifying goals and needs, and efficiently finding
              solutions to a broad variety of problems and user pain points.
            </p>
            <p className="font-m app-font-rg">
              I am passionate about products and organizations that make a
              positive impact on people’s lives. While in the midst of all that,
              technology started to become a big part of my life as well, and my
              interest in the constantly evolving digital world continued to
              grow.
            </p>
            <p className="font-m app-font-rg">
              Things I enjoy: Cycling <font size="4">🚴 , </font>
              Games(COD) <font size="4">🎮 , </font>
              Travelling <font size="5">✈️ , </font>
              Gardening <font size="4">🌿</font>
            </p>
            <div className="w-100 col-12 py-4 text-start">
              <button
                className="btn btn-outline-dark cstm_btn_dark"
                value="DOWNLOAD  RESUME"
              >
                <a className="no_dcrtn" href="/assets/docs/Resume.jonafrank.pdf" download>DOWNLOAD&nbsp; RESUME</a>
              </button>
            </div>
          </div>
          <div className="col-6 text-end p-4">
            <img className="img-fluid" width="80%" src={jf} alt="About_image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
