import React from "react";
import "./ds.scss";

import ds1 from "../../../../../assets/images/ds/5.png";
import ds2 from "../../../../../assets/images/ds/10.png";
import ds3 from "../../../../../assets/images/ds/14.svg";
import ds4 from "../../../../../assets/images/ds/4.png";
import ds5 from "../../../../../assets/images/ds/13.png";
import ds6 from "../../../../../assets/images/ds/11.png";
import ds7 from "../../../../../assets/images/ds/13.png";
import ds8 from "../../../../../assets/images/ds/1.png";
import ds9 from "../../../../../assets/images/ds/12.png";
import ds10 from "../../../../../assets/images/ds/15.png";
import ds11 from "../../../../../assets/images/ds/12.png";
import ds12 from "../../../../../assets/images/ds/3.png";
import ds13 from "../../../../../assets/images/ds/9.png";
import ds14 from "../../../../../assets/images/ds/2.png";
// import ds15 from "../../../../../assets/images/ds/2.png";

function DesignSystems(props) {
  return (
    <>
      <div className="w-100 app-bg-dark-green dsBg">
        <div className="w-100 col-12 app-bg-dark-green h-100 position-relative">
          <div className="container">
            <div className="position-absolute app-bg-dark-blue h-75 box_down d-flex align-items-start flex-column justify-content-center">
              <div className="py-4">
                <img src={ds1} alt="ds1" className="img-fluid" />
              </div>
              <h1 className="font-xxxxl app-font-bold">Wolterskluwer</h1>
              <h1 className="font-xxxl app-font-smBold">Design System</h1>
            </div>
          </div>
          <div className="position-absolute floating_section">
            <div className="fl_section_1">
              <div className="fl_section_2"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 app-bg-white py-5">
        <div className="container">
          <div className="col-12 px-2 d-flex flex-row">
            <div className="col-6 px-2">
              <h1 className="font-xxl app-font-bold">
                What is a design system?
              </h1>
              <p className="mt-3 font-m app-font-rg">
                A design system is a collection of reusable components, guided
                by clear standards, that can be assembled together to build any
                number of applications.
              </p>
              <p className="mt-3 font-m app-font-rg">
                A design system is adopted by and supported for other teams
                making experiences. These teams use it to develop and ship
                features more efficiently to form a more cohesive customer
                journey.
              </p>
            </div>
            <div className="col-6 px-2">
              <h1 className="font-xxxl app-font-bold" style={{ opacity: 0 }}>
                Temp
              </h1>
              <p className="font-m app-font-rg">
                A design system offers a library of visual style, components,
                and other concerns documented and released by an individual,
                team or community as code and design tools so that adopting
                products can be more efficient and cohesive.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 app-bg-white py-5">
        <img src={ds2} alt="ds2" className="img-fluid" />
      </div>
      <div className="w-100 app-bg-white py-5">
        <div className="container">
          <div className="col-12 d-flex flex-row">
            <div className="col-6 px-2">
              <div className="col-12 p-5">
                <h1 className="font-xxl app-font-bold">My Role</h1>
                <p className="mt-3 font-m app-font-rg">
                  I was part of a cross-functional team that had weekly meetings
                  to sync about this initiative. And my duties varied from
                  research, definition, UI design & documentation along with
                  global UX designers. To learn the proper approach for the
                  definition of the structure and guidelines of our components,
                  we set up a team of designers and engineers. Our high-level
                  objective was to have a set of elements that could coherently
                  co-exist within a larger system, which is why we adopted the
                  Atomic Design methodology.
                </p>
              </div>
            </div>
            <div className="col-6 px-2 blue_bg_light">
              <div className="col-12 p-5 d-flex h-100 flex-column align-items-center justify-content-center">
                <div className="col-12">
                  <img src={ds3} alt="dblqt" className="img-fluid cstm_svg" />
                </div>
                <div className="pt-3 col-12">
                  <p className="mt-3 font-l app-font-bold">
                    Crafting a design system is about clearing the way for
                    others to invent and imagine.
                  </p>
                  <p className="mt-3 font-m app-font-rg">– Josh Clark </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 app-bg-white py-5">
        <div className="container">
          <div className="col-12 d-flex flex-row">
            <div className="col-6 px-2">
              <div className="col-12 p-5 app-bg-dark-green d-flex flex-column h-100 justify-content-center">
                <div className="pt-3">
                  <p className="mt-3 font-l app-font-bold text-white">
                    We don’t have a clear theming for our semantic components
                    (Errors, Warning, Confirmation…)”
                  </p>
                  <p className="mt-3 font-m app-font-rg text-white">
                    - Product Managers
                  </p>
                </div>
                <div className="pt-3">
                  <p className="mt-3 font-l app-font-bold text-white">
                    “I can’t re-use a component from another project, since they
                    don’t have a similar structure.”
                  </p>
                  <p className="mt-3 font-m app-font-rg text-white">
                    - Front End Engineer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 px-2">
              <div className="col-12 px-5">
                <h1 className="font-xxl app-font-bold">Problem</h1>
                <p className="mt-2 font-m app-font-rg">
                  When identifying the problems in terms of product design,
                  Wolterskluwer rapid growth was unstructured i.e, lack of a
                  design system. This led to severe user experience
                  inconsistency across platforms. To better understand the
                  different problems in our product, we conducted interviews
                  with people from different departments (Front end developers,
                  Product Managers, Engineering managers)
                </p>
                <p className="font-m app-font-rg">
                  After these initial interviews, we discovered that we need to:
                </p>
                <p className="font-m app-font-rg">
                  * Identify all the design issues in our product
                </p>
                <p className="font-m app-font-rg">
                  * Learn the common standard and principles of a successful
                  design
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light w-100 py-5">
        <div className="container">
          <div className="col-12 d-flex flex-row">
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxxl app-font-bold mb-4">Key Findings</h1>
                <h1 className="font-xxl app-font-bold">Inconsistency</h1>
                <p className="mt-2 font-m app-font-rg">
                  As you can see, our product had many inconsistencies, be it in
                  colors, components, guidelines…, which can have detrimental
                  effects on the overall experience & the brand image.
                </p>
              </div>
              <div className="col-12 pb-3">
                <h1 className="font-xxl app-font-bold">Redundancy</h1>
                <p className="mt-2 font-m app-font-rg">
                  For designers, not having a library of components means you’ll
                  have to do repetitive design work to create your product
                  screens. This also applies to the implementation, since
                  developers would have to spend more time and effort to create
                  new components.
                </p>
              </div>
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Communication</h1>
                <p className="mt-2 font-m app-font-rg">
                  Commonly, a design system should have a consistent structure
                  in which textual elements and feedback messages are formed.
                  More than that, it should have a library of illustrations that
                  are user friendly to strengthen the brand’s feel within the
                  product and help convey complex ideas in a simple way.
                </p>
              </div>
            </div>
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxxl app-font-bold mb-4" style={{opacity: 0}}>Key Findings</h1>
                <h1 className="font-xxl app-font-bold">Accessibility</h1>
                <p className="mt-2 font-m app-font-rg">
                  For a product that’s used by millions, we need to accommodate
                  our different types of users, including the ones with visual,
                  auditory, and motor impairments to create inclusive
                  experiences.
                </p>
              </div>
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Flexibility</h1>
                <p className="mt-2 font-m app-font-rg">
                  The systems’ modularity ensures maximum flexibility in
                  execution. Their components are designed to work seamlessly
                  with each other and in whichever combination suits the needs
                  of the user across platforms. Also, when it comes to color
                  theming, Google Material has practically approached this
                  through their *principles of color,* which is aligned with our
                  long-term vision of verticalization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100">
        <div className="container py-4">
          <div className="row py-5">
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxxl app-font-bold">Process</h1>
                <h1 className="font-xxl app-font-bold">Reference Designs</h1>
                <p className="mt-2 font-m app-font-rg">
                  We started with compiling page design concepts spanning
                  products and platforms to normalize the visual language and
                  component design decisions. The goal of reference designs is
                  not intended to be explorations of project-specific details,
                  but a reference for designing a visual language and
                  identifying core components across products.
                </p>
              </div>
            </div>
            <div className="col-6 px-2 text-center">
              <img src={ds4} alt="ds4" />
            </div>
          </div>
          <div className="row py-5">
            <div className="col-6 px-2 text-center">
              <img src={ds5} alt="ds5" />
            </div>
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxl app-font-bold">
                  Prioritize System Parts
                </h1>
                <p className="mt-2 font-m app-font-rg">
                  We conducted a workshop with the cross teams including
                  developers, program project leads, and design and UX to
                  identify what parts the team would prioritize for this system.
                </p>
                <p className="mt-2 font-m app-font-rg">
                  As a result, we set the goal for next relase of the design
                  system to build the foundation by creating a compressive,
                  scalable visual language and build atomic components most
                  often reused and combined to make user experiences. Our
                  emphasis in this process was to emphasize data collection and
                  displays through normalization of form controls and
                  standardize UX patterns and best practices.
                </p>
              </div>
            </div>
          </div>
          <div className="dotted_brdr w-100"></div>
          <div className="row py-5">
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxl app-font-bold">Visual Elements</h1>
                <p className="mt-2 font-m app-font-rg">
                  Good design should undergird everything we do at Help Scout.
                  It gives the brand life, and helps us share our message in a
                  way that’s inspirational and memorable. If we don’t have time
                  to create something beautiful and consistent with who we are
                  as a brand, we won’t spend time on it at all.
                </p>
              </div>
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Logos</h1>
                <p className="mt-2 font-m app-font-rg">
                  The Help Wolterskluwer logo is the most immediate
                  representation of our company, our culture, and our brand.
                  These guidelines describe how to use it consistently in its
                  proper, approved forms.
                </p>
              </div>
            </div>
            <div className="col-6 px-2 text-center">
              <img className="img-fluid" width="50%" src={ds6} alt="ds6" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 py-5 app-bg-dark-blue">
        <div className="container">
          <div className="row py-4">
            <div className="col-6 px-4 text-white d-flex align-items-start flex-column justify-content-center">
              <h1 className="font-xxl app-font-bold">Colors</h1>
              <p className="mt-2 font-m app-font-rg">
                The colors in our palette communicate the reliability of our
                product and the authenticity of our team’s values. They enhance
                the user experience, from the way customers engage with our
                product to the connections we forge through our content.
              </p>
            </div>
            <div className="col-6 px-2 text-center">
              <img src={ds7} alt="ds7" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100 py-5">
        <div className="container py-4">
          <img src={ds8} alt="ds8" className="img-fluid" />
        </div>
      </div>
      <div className="bg-light w-100 py-5">
        <div className="container py-4">
          <div className="row">
            <div className="col-6 px-4 text-dark d-flex align-items-start flex-column justify-content-center">
              <h1 className="font-xxl app-font-bold">Data Visualization</h1>
              <p className="mt-2 font-m app-font-rg">
                Data helps us make informed decisions. Therefore, data should be
                clear; it should not be overly decorative and it should be
                presented in a meaningful way. Below are a few standards that
                we’ve set for telling visual data stories.
              </p>
            </div>
            <div className="col-6 text-center">
              <img src={ds9} alt="ds9" width="75%" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100 py-5">
        <div className="container py-4">
          <div className="row">
            <div className="col-6 text-center">
              <img src={ds10} alt="ds10" width="75%" className="img-fluid" />
            </div>
            <div className="col-6 px-4 text-dark d-flex align-items-start flex-column justify-content-center">
              <h1 className="font-xxl app-font-bold">Typography</h1>
              <p className="mt-2 font-m app-font-rg">
                The primary brand typeface for Wolterskluwer. An elegant San -
                Serif infused with a quirky warmth, this headline face projects
                trustworthiness, optimism, and sincerity. The light weight sets
                an editorial tone while uncommon rounded corners infuse the
                typeface with a distinctive personality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="app-bg-dark-green w-100 py-5">
        <div className="container py-4">
          <div className="row">
            <div className="col-6 px-4 text-dark d-flex align-items-start flex-column justify-content-center">
              <h1 className="font-xxl app-font-bold">Components</h1>
              <p className="mt-2 font-m app-font-rg">
                Components are the reusable building blocks of our design
                system. Each component meets a specific interaction or UI need,
                and has been specifically created to work together to create
                patterns and intuitive user experiences.
              </p>
            </div>
            <div className="col-6 text-center">
              <img src={ds11} alt="ds11" width="75%" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="app-bg-white w-100 pb-5">
        <div className="container">
          <div className="row">
            <img src={ds12} alt="ds12" width="75%" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="bg-light w-100 py-5">
        <div className="container">
          <div className="row py-5">
            <img src={ds13} alt="ds13" width="75%" className="img-fluid" />
          </div>
          <div className="row pt-5">
            <img src={ds14} alt="ds14" width="75%" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="bg-light w-100 py-5">
        <div className="container">
          <div className="col-12 d-flex flex-row">
            <div className="col-6 px-2">
              <div className="col-12 pb-3">
                <h1 className="font-xxl app-font-bold mb-4">Outcome Results</h1>
                <p className="mt-2 font-m app-font-rg">
                  Within the organization, utilization of the system increased
                  design and development collaboration. It also served to
                  improve efficiency in developing new tools, leading to a more
                  cohesive, higher-quality user experience. It created a
                  foundation for building accessible and usable products.
                </p>
              </div>
              <div className="col-12 pb-3">
                <h1 className="font-xxl app-font-bold">Development</h1>
                <p className="mt-2 font-m app-font-rg">
                  This simplified the life of developers. Speaking one language
                  with the design team when discussing implementations. Focus on
                  the feature not the low-level UI primitives (color & space
                  values, small components, interactions, states, etc).
                </p>
              </div>
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Design</h1>
                <p className="mt-2 font-m app-font-rg">
                  Having a ready-to-use library of components made creating
                  highfidelity interfaces a straightforward task by bringing
                  designing interfaces from days to a couple of hours. The
                  design system allowed us to quickly build prototypes, test
                  more ideas than before, quickly evaluate our hypotheses, and
                  even create more variations to A/B test
                </p>
              </div>
            </div>
            <div className="col-6 px-2 d-flex align-items-center justify-content-center">
              <img src={ds4} alt="ds15" width="75%" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DesignSystems;
