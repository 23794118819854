import "./App.css";
import "./assets/css/bootstrap.min.css";

import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./components/full-layout/full-layout";
import HomePage from "./components/pages/home/home";
import AboutPage from "./components/pages/about/about";
import ContactPage from "./components/pages/contact/contact";
import DigitalClassrooms from "./components/pages/work/projects/dc/dc";
import EntityManagement from "./components/pages/work/projects/em/em";
import DesignSystems from "./components/pages/work/projects/ds/ds";
import Iqvia from "./components/pages/work/projects/iqvia/iqvia";
import AvignaProperties from "./components/pages/work/projects/ag/ag";

const router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: '', element: <HomePage />
      },
      {
        path: 'work', element: <HomePage />
      },
      {
        path: 'about', element: <AboutPage />
      },
      {
        path: "/contact",
        element: <ContactPage />
      },
      {
        path: "/dc",
        element: <DigitalClassrooms />
      },
      {
        path: "/ent-mng",
        element: <EntityManagement />
      },
      {
        path: "/ds",
        element: <DesignSystems />
      },
      {
        path: '/iqvia',
        element: <Iqvia />
      },
      {
        path: '/avigna',
        element: <AvignaProperties />
      }
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
