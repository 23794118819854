import React from "react";
import ReactDOM from "react-dom/client";
import ToasterComponent from "./toaster/toaster-component";
import PasswordComp from "./password/password-comp";

const SetToast = (type, children) => {
  const el = document.getElementById("portal");
  let root = ReactDOM.createRoot(el);

  const toasterClose = (e) => {
    root.unmount();
    console.log("Toast Close");
  };

  return root.render(
    <ToasterComponent
      toastView={true}
      type={type}
      onClose={(event) => toasterClose(event)}
    >
      {children}
    </ToasterComponent>
  );
};

const SetPassword = (isPwdValid) => {
  const el = document.getElementById("portal");
  let root = ReactDOM.createRoot(el);

  const passwordClose = (val) => {
    if (val === "Jona@2023") {
        root.unmount();
        isPwdValid(true);
    } else {
        isPwdValid(false);
    }
  };

  return root.render(
    <PasswordComp close={(e) => passwordClose(e)} onExit={() => root.unmount()}></PasswordComp>
  );
};

export const ToasterService = {
  SetToast: SetToast,
  SetPassword: SetPassword,
};
