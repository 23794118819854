import React, { useState } from "react";
import "./toaster.scss";
function ToasterComponent({ toastView, type, onClose, children }) {
  const [isOpen, setIsOpen] = useState(toastView);

  const closeToaster = () => {
    setIsOpen(false);
    onClose(false);
  };

  return (
    <>
      {isOpen && (
        <div className={`toaster-wrapper ${type && type.toString()}`}>
          <div className="toaster-layout">
            <div className="toaster-header">This is Toaster Component</div>Í
            <div className="toaster-content">{children}</div>
          </div>
          <button className="toaster-close" onClick={closeToaster}>
            <font size="4">X</font>
          </button>
        </div>
      )}
    </>
  );
}

export default ToasterComponent;
