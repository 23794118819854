import React, { useState } from "react";
import "./iqvia.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// import p1 from "../../../../../assets/images/ent-mng/10.png";
// import p2 from "../../../../../assets/images/ent-mng/14.png";
// import p3 from "../../../../../assets/images/ent-mng/16.png";
// import p4 from "../../../../../assets/images/ent-mng/18.png";
// import p5 from "../../../../../assets/images/ent-mng/19.png";
// import p6 from "../../../../../assets/images/ent-mng/17.png";
// import p7 from "../../../../../assets/images/ent-mng/22.png";
// import p8 from "../../../../../assets/images/ent-mng/21.png";
// import p9 from "../../../../../assets/images/ent-mng/20.png";
// import p10 from "../../../../../assets/images/ent-mng/15.png";
// import p11 from "../../../../../assets/images/ent-mng/12.png";
// import p12 from "../../../../../assets/images/ent-mng/11.png";
// import p13 from "../../../../../assets/images/ent-mng/8.png";
// import p14 from "../../../../../assets/images/ent-mng/7.png";
// import p15 from "../../../../../assets/images/ent-mng/5.png";
// import p16 from "../../../../../assets/images/ent-mng/4.png";
// import p17 from "../../../../../assets/images/ent-mng/13.png";

// import p18 from "../../../../../assets/images/ent-mng/1.png";

import p19 from "../../../../../assets/images/iqvia/1.png";
import p20 from "../../../../../assets/images/iqvia/2.png";
import p21 from "../../../../../assets/images/iqvia/3.png";
import p22 from "../../../../../assets/images/iqvia/4.png";
import p23 from "../../../../../assets/images/iqvia/5.png";
import p24 from "../../../../../assets/images/iqvia/6.png";
import p25 from "../../../../../assets/images/iqvia/7.png";
import p26 from "../../../../../assets/images/iqvia/8.png";
import p27 from "../../../../../assets/images/iqvia/9.png";
import p28 from "../../../../../assets/images/iqvia/10.png";
import p29 from "../../../../../assets/images/iqvia/11.png";
import p30 from "../../../../../assets/images/iqvia/12.png";
import p31 from "../../../../../assets/images/iqvia/13.png";
import p32 from "../../../../../assets/images/iqvia/14.png";
import p33 from "../../../../../assets/images/iqvia/15.png";
import p34 from "../../../../../assets/images/iqvia/16.png";
import p35 from "../../../../../assets/images/iqvia/17.png";
import p36 from "../../../../../assets/images/iqvia/18.png";
import p37 from "../../../../../assets/images/iqvia/19.png";
import p38 from "../../../../../assets/images/iqvia/20.png";
// web
import p39 from "../../../../../assets/images/iqvia/26.png";
import p40 from "../../../../../assets/images/iqvia/27.png";
import p41 from "../../../../../assets/images/iqvia/28.png";
import p42 from "../../../../../assets/images/iqvia/29.png";
import p43 from "../../../../../assets/images/iqvia/30.png";
import p44 from "../../../../../assets/images/iqvia/31.png";

import p101 from "../../../../../assets/images/iqvia/101.png";
import p102 from "../../../../../assets/images/iqvia/102.png";
import p103 from "../../../../../assets/images/iqvia/103.png";
import p104 from "../../../../../assets/images/iqvia/104.png";
import p105 from "../../../../../assets/images/iqvia/105.png";
import p106 from "../../../../../assets/images/iqvia/106.png";
import p107 from "../../../../../assets/images/iqvia/107.png";
import p108 from "../../../../../assets/images/iqvia/108.png";

// Mobile
// import m1 from "../../../../../assets/images/iqvia/mobile/1.png";
import m2 from "../../../../../assets/images/iqvia/mobile/2.png";
// import m3 from "../../../../../assets/images/iqvia/mobile/3.png";
// import m4 from "../../../../../assets/images/iqvia/mobile/4.png";
import m5 from "../../../../../assets/images/iqvia/mobile/5.png";
import m6 from "../../../../../assets/images/iqvia/mobile/6.png";
// import m7 from "../../../../../assets/images/iqvia/mobile/7.png";
import m8 from "../../../../../assets/images/iqvia/mobile/8.png";
import m9 from "../../../../../assets/images/iqvia/mobile/9.png";
import m10 from "../../../../../assets/images/iqvia/mobile/10.png";
import m11 from "../../../../../assets/images/iqvia/mobile/11.png";
import m12 from "../../../../../assets/images/iqvia/mobile/12.png";
import m13 from "../../../../../assets/images/iqvia/mobile/13.png";
import m14 from "../../../../../assets/images/iqvia/mobile/14.png";
import m15 from "../../../../../assets/images/iqvia/mobile/15.png";
import m16 from "../../../../../assets/images/iqvia/mobile/16.png";
import m17 from "../../../../../assets/images/iqvia/mobile/17.png";
// import m18 from "../../../../../assets/images/iqvia/mobile/18.png";
// import m19 from "../../../../../assets/images/iqvia/mobile/19.png";
import m20 from "../../../../../assets/images/iqvia/mobile/20.png";
import m21 from "../../../../../assets/images/iqvia/mobile/21.png";

import v1 from "../../../../../assets/images/iqvia/mv1.mp4";
import v2 from "../../../../../assets/images/iqvia/mv2.mp4";

function Iqvia() {
  const [btnState, setBtnState] = useState("btn1");

  const btnClick = (btn) => {
    console.log('Function Call');
    setBtnState(btn);
  };

  return (
    <>
      <div>
        <div className="w-100 app-bg-white emBg">
          <div className="w-100 h-100">
            <div className="col-12 d-flex flex-row h-100 align-items-center">
              <div className="col-5 py-5">
                <div className="col" style={{ marginLeft: "25%" }}>
                  <h1 className="app-font-bold font-xxxxl mb-3"> IQVIA</h1>
                  <h1 className="font-xxl app-font-smBold text-primary">
                    DCT Solutions
                  </h1>
                  <h4 className="mt-4 w-100 font-xl app-font-md">
                    UX UI DESIGN | UX RESEARCH
                  </h4>
                  <h4 className="mt-4 w-100 font-l app-font-md">
                    Domain: Healthcare
                  </h4>
                </div>
              </div>
              <div className="col-7 h-100">
                <img src={p19} alt="em_banner" className="h-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pt-5 d-flex flex-row flex-nowrap">
              <div className="col-8">
                <div className="col-10 mr-auto">
                  <h1 className="font-xxl app-font-bold">What is IQVIA?</h1>
                  <p className="mt-3 font-m app-font-rg">
                    IQVIA is a leading global provider of advanced analytics,
                    technology solutions, and clinical research services to the
                    life sciences industry. IQVIA creates intelligent
                    connections across all aspects of healthcare through its
                    analytics, transformative technology, big data resources and
                    extensive domain expertise. IQVIA Connected Intelligence™
                    delivers powerful insights with speed and agility — enabling
                    customers to accelerate the clinical development and
                    commercialization of innovative medical treatments that
                    improve healthcare outcomes for patients.
                  </p>
                </div>
                <div className="col-10 mr-auto py-5">
                  <h1 className="font-xxl app-font-bold">
                    About DCT Solutions
                  </h1>
                  <p className="mt-3 font-m app-font-rg">
                    IQVIA Decentralized Trials(DCT) deliver purpose-built
                    clinical services and technologies that engage the right
                    patients wherever they are. Our hybrid and fully virtual
                    solutions have been used more than any others. With more
                    than many decentralized clinical trials (DCT) underway
                    across many countries, IQVIA DCT has the experience,
                    capabilities and reach to meet any sponsor's needs.
                  </p>
                </div>
              </div>
              <div className="col-4 d-flex flex-column">
                <div className="col-8 ml-auto">
                  <h1 className="font-xxl app-font-bold">Client</h1>
                  <p className="mt-3 font-m app-font-rg">IQVIA</p>
                </div>
                <div className="col-8 pt-4 ml-auto">
                  <h1 className="font-xxl app-font-bold">My Role</h1>
                  <p className="mt-3 font-m app-font-rg">- UX Research</p>
                  <p className="mt-3 font-m app-font-rg">- UX/UI Design</p>
                  <p className="mt-3 font-m app-font-rg">- User Testing</p>
                </div>
              </div>
            </div>
            <div className="col-11 mx-auto py-3">
              <h1 className="font-xxl app-font-bold">User Problems</h1>
              <p className="font-m app-font-rg pt-2">
                <strong>Primary:</strong>
              </p>
              <p className="font-m app-font-rg">
                - Poor acceptance of DCT Software.
              </p>
              <p className="font-m app-font-rg">
                - Diagnostic errors are a massive patient safety hazard,
                resulting in care gaps, unnecessary procedures, and patient
                harm.
              </p>
              <p className="font-m app-font-rg">
                - Clinical decision support interventions can help combat
                diagnostic errors by identifying ordered tests that haven't been
                done or by flagging incidental findings that require follow-up.
              </p>
              <p className="font-m app-font-rg">
                - DCT Olderversion needs more effort, time, adds more works for
                contacting visit and scheduling appointment. There are high
                chances of DCT to get slow down and cause a decrease in
                productivity.
              </p>
              <p className="font-m app-font-rg">
                - Shortage of health informatics professionals who are well
                capable of establishing and implementing the techniques.
              </p>
              <p className="font-m app-font-rg">
                - Sometimes, these errors can be attributed to information
                missed by clinical decision support systems.
              </p>
              <p className="font-m app-font-rg">
                - The lack of time allowed training and learning on making use
                of the HMS,
              </p>
              <p className="font-m app-font-rg">
                - The lack of healthcare professional support, motivation, and
                more.
              </p>
              <p className="font-m app-font-rg">
                - Other few technical challenges that fail the implementation of
                DCT in the healthcare industry includes Networks and computer
                have different maintenance problems, lack of no standards for
                Data entry and data retrieval, difficulties in training users
                technically to use DCT.
              </p>
              <h1 className="font-xxl app-font-bold pt-3">Affinity Map</h1>
              <p className="font-m app-font-rg pt-3">
                With the results of my survey and interview research analysis, I
                synthesized the data collected through the creation of an
                affinity map. This process is a great method to help me make
                sense of all my information when I have a lot of mixed data.
                After synthesizing the data, I built user personas, user
                journey’s, and user flows showcasing the data grouped based on
                identified themes, and a list of insights supported by relevant
                examples and presented in a logical, visually engaging way.
              </p>
              <div className="col-12 pb-5">
                <img src={p26} alt="P26" width="80%" />
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Competitor Analysis</h1>
                <p className="font-m app-font-rg">
                  I chose two potential competitors for my product and built
                  their marketing profile, SWOT profile and conducted a UX
                  analysis of their products. The goal was to identify what type
                  of problems users encounter with competitor products, as well
                  as understand what the users might expect from my product.
                </p>
                <p className="font-m app-font-rg">
                  During competitor analysis I have identified the services
                  provided by direct and indirect competitors in US, in order to
                  understand their strengths and shortcomings. Competitor
                  Analysis report containing competitor feature list and the
                  advantages was created as part of the process.
                </p>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <Carousel
                autoPlay
                showThumbs={false}
                showArrows={false}
                showStatus={false}
              >
                <div>
                  <img alt="20" src={p20} width="100%" height="auto" />
                </div>
                <div>
                  <img alt="21" src={p21} width="100%" height="auto" />
                </div>
                <div>
                  <img alt="22" src={p22} width="75%" height="auto" />
                </div>
              </Carousel>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">User Journey map</h1>
                <p className="font-m app-font-rg">
                  By creating persona journey maps, I wanted to illustrate the
                  process of how Lan, Micheal, and Kate behave, feel and what
                  they think while accomplishing their goals to detect pain
                  points or moments of delight.
                </p>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <Carousel
                autoPlay
                showThumbs={false}
                showArrows={false}
                showStatus={false}
              >
                <div>
                  <img alt="23" src={p23} />
                </div>
                <div>
                  <img alt="24" src={p24} />
                </div>
                <div>
                  <img alt="25" src={p25} />
                </div>
              </Carousel>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Persona</h1>
                <p className="font-m app-font-rg">
                  Persona was created reflecting the interviewed users
                  lifestyle, routine, goals, motivations, pain points etc.
                </p>
              </div>
              <div className="col-12 d-flex flex-row justify-content-around">
                <div className="col-6 mr-auto">
                  <img alt="27" src={p27} width="90%" />
                </div>
                <div className="col-6 mr-auto">
                  <img alt="28" src={p28} width="90%" />
                </div>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold pb-3">User Interview</h1>
                <Carousel
                  autoPlay
                  showThumbs={false}
                  showArrows={false}
                  dynamicHeight={false}
                  showStatus={false}
                >
                  <div>
                    <img alt="29" src={p29} />
                  </div>
                  <div>
                    <img alt="30" src={p30} />
                  </div>
                  <div>
                    <img alt="31" src={p31} />
                  </div>
                </Carousel>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold pb-2">
                  Business and Products Flow
                </h1>
                <p className="font-m app-font-rg">
                  A feature roadmap was developed using inputs from interview
                  and secondary research. Must have MVP features requested by
                  participants as essential to DCT application given high
                  priority. Using overall research findings i.e. goals and pain
                  points of both business and users were defined to establish
                  project goals.
                </p>
              </div>
              <div className="col-12 pt-3">
                <div className="col-9 mr-auto">
                  <img alt="32" src={p32} width="100%" />
                </div>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold pb-2">Wireframes</h1>
                <p className="font-m app-font-rg">
                  Taking the Low-fi wireframe sketches, I digitized them on
                  Sketch and added just enough information for users to be able
                  to navigate through other entity pages and complete tasks, I
                  would present to them during usability testing for initial
                  level of sprint. These mid-fidelity wireframes would help my
                  focus on what needed to be improved in terms of the
                  functionality of my design. I also created tablet and mobile
                  versions to make sure that the design is responsive and
                  effective across the different device screens that users would
                  be accessing the website on. Once the testing was concluded we
                  implemented the feedback into the mid-fidelity (mid-fi)
                  prototypes, and handed them to the UI team to skin into their
                  hi-fi form.
                </p>
              </div>
              <div className="col-12 pt-3">
                <div className="col-12">
                  <Carousel
                    autoPlay
                    showThumbs={false}
                    showArrows={false}
                    dynamicHeight={false}
                    showStatus={false}
                    stopOnHover={true}
                  >
                    <div>
                      <img alt="33" src={p33} />
                    </div>
                    <div>
                      <img alt="34" src={p34} />
                    </div>
                    <div>
                      <img alt="35" src={p35} />
                    </div>
                    <div>
                      <img alt="36" src={p36} />
                    </div>
                    <div>
                      <img alt="37" src={p37} />
                    </div>
                    {/* <div>
                      <img alt="38" src={p38} />
                    </div> */}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto">
              <h3 className="font-xxl app-font-bold pb-2">Visual Design</h3>
              <div className="col-12 mr-auto py-5">
                <div className="col-12 d-flex flex-row justify-content-start align-items-center">
                  {/* <button
                    className={
                      btnState === "btn1" ? "active tab_btn" : "tab_btn"
                    }
                    onClick={() => btnClick("btn1")}
                    type="button"
                  >
                    Web
                  </button>
                  <button
                    className={
                      btnState === "btn2" ? "active tab_btn" : "tab_btn"
                    }
                    onClick={() => btnClick("btn2")}
                    type="button"
                  >
                    Mobile
                  </button> */}
                  <div className="flight-types">
                    <input
                      type="radio"
                      name="flight-type"
                      value="Web"
                      id="Web"
                      checked={btnState === 'btn1'}
                      onChange={()=> null}
                      onClick={() => btnClick("btn1")}
                    />
                    <label className="app-font-md" htmlFor="Web">Web</label>
                    <input
                      type="radio"
                      name="flight-type"
                      value="Mobile"
                      id="Mobile"
                      onChange={() => null}
                      onClick={() => btnClick("btn2")}
                    />
                    <label className="app-font-md" htmlFor="Mobile">Mobile</label>
                  </div>
                </div>
                <>
                  {btnState === "btn1" ? (
                    <div className="col-12 py-5">
                      <div className="col-12 d-flex flex-wrap">
                        <div className="col-6 pb-3 px-2">
                          <img alt="39" width="100%" height="auto" src={p39} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="40" width="100%" height="auto" src={p40} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="41" width="100%" height="auto" src={p41} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="42" width="100%" height="auto" src={p42} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="43" width="100%" height="auto" src={p43} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="44" width="100%" height="auto" src={p44} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="101" width="100%" height="auto" src={p101} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="102" width="100%" height="auto" src={p102} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="103" width="100%" height="auto" src={p103} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="104" width="100%" height="auto" src={p104} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="105" width="100%" height="auto" src={p105} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="106" width="100%" height="auto" src={p106} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="107" width="100%" height="auto" src={p107} />
                        </div>
                        <div className="col-6 pb-3 px-2">
                          <img alt="108" width="100%" height="auto" src={p108} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 py-5">
                      <div className="col-12 d-flex flex-wrap">
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m13"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m13}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m5"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m5}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m6"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m6}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m8"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m8}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m20"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m20}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m14"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m14}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m15"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m15}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m16"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m16}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m17"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m17}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m10"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m10}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m9"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m9}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m12"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m12}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m11"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m11}
                          />
                        </div>
                        <div className="col-3 pb-3 px-2">
                          <img
                            alt="m21"
                            className="mImage"
                            width="100%"
                            height="auto"
                            src={m21}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">
                  Usability Test Plan and Results
                </h1>
                <p className="font-m app-font-rg pt-2">
                  Usability Test Plan and Results The purpose of my usability
                  test was to assess the learnability for new users interacting
                  with the Health Pal application for the first time on a mobile
                  iOS device. I observed and measured the success rate of 6
                  participants completing 2 task attempts each. I documented
                  whether the participants understood my product, the value of
                  my three core features, and how to achieve essential
                  functions.
                </p>
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12 d-flex">
                <div className="col-6 px-2">
                  <video controls width="100%" height="auto">
                    <source src={v1} type="video/mp4" />
                  </video>
                </div>
                <div className="col-6 px-2">
                  <video controls width="100%" height="auto">
                    <source src={v2} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-100 app-bg-blue py-5">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto">
              <h3 className="font-xxl app-font-bold pb-2">Work Flow</h3>
              <div className="col-12 d-flex flex-row flex-wrap">
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Product Brief</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Competitor Analysis</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Customer Journeymap</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">User interviews & Survey</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Information Architecture</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Wireframe & Final Solutions</h4>
                  </div>
                </div>
                <div className="col">
                  <div className="green_crcl">
                    <h4 className="crcl_txt">Outcome Results</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Competitor Analysis</h1>
                <p className="font-m app-font-rg">
                  Before taking up user interviews,
                </p>
                <p className="font-m app-font-rg">- More Paper works</p>
                <p className="font-m app-font-rg">
                  - I wanted to understand the requirements and problems in the
                  field by looking at what the competitors were doing.
                </p>
                <p className="font-m app-font-rg">
                  - I checked out several website and and their competitors
                  portal help of SWOT analysis, checked out their reviews and
                  interface, services. Exploring each of their websites and
                  their services, I evaluated the strengths and weaknesses of
                  each to see how we could fill in any gaps moving forward.
                </p>
                <p className="font-m app-font-rg">
                  - To conclude, we required case cards with simple information
                  and a good search engine to proceed up ahead.
                </p>
              </div>
              <div className="col-12 d-flex flex-row flex-nowrap py-5">
                <img src={p1} className="img-fluid" alt="comps" />
              </div>
            </div>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Customer Journey Map</h1>
                <p className="font-m app-font-rg pt-2">
                  Improve customer experience at every touchpoint with an
                  accurate snapshot of all the ways customers interact with our
                  brand. The most common pain point. “It was really eye-opening
                  to hear about the frustration caused by the gap between
                  expectation and reality,” says Kevin. Customers believed it
                  had the potential to be helpful but found it difficult to
                  schedule and didn’t know what to expect. As a result, they
                  came up with their own ideas about what it would include, then
                  came away disappointed when it didn’t. Relatively high ratings
                  even in areas that caused some friction indicated that despite
                  room for improvement, customers were generally satisfied with
                  their decision to take up subscription plans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100 py-5">
          <div className="container py-5">
            <img src={p2} className="img-fluid" alt="cst-jrny" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Persona</h1>
                <p className="font-m app-font-rg pt-2">
                  Based on hearing to different stories and comments, I came up
                  with a User Persona, I created the profiles, their mission
                  statement, end goals and frustrations to better understand the
                  user. To make sure that my decisions moving forward in the
                  process are user-centered, I wanted to have a clear
                  understanding of who Wolterskluwer users are. Using what I
                  learned from patterns from my empathy map, I created a user
                  persona to represent who I will be designing for Meet -
                  Robert, Lisa, Christina, David.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-6 p-4">
                <img className="img-fluid" src={p3} alt="pr1" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p4} alt="pr2" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p5} alt="pr3" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p6} alt="pr4" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">
                  User Interview and Survey
                </h1>
                <p className="font-m app-font-rg pt-2">
                  To dig deeper into the problems faced by the small
                  business,corporate, Law firms and I decided to take personal
                  interviews on remote due to pandemic situation. Out of the
                  four small business, two corporate, and one law firms I
                  interviewed on call, some with the help of my client. I have
                  prepared the userinterview questions based on the type of user
                  business. For the suvey we have listed down the few question
                  samples for moderate testing with the user. I gathered 4
                  participants (2 women and 2 men between the ages of 30 and
                  65), who I interviewed via Zoom and Google Meet.
                </p>
                <p className="font-m app-font-rg pt-2">
                  <strong>Survey Tool: Userzoom, Google Meet</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-secondary-green w-100 py-5">
          <div className="container py-3" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-3 p-2">
                <img className="img-fluid" src={p7} alt="pr7" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p8} alt="pr8" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p7} alt="pr9" />
              </div>
              <div className="col-3 p-2">
                <img className="img-fluid" src={p8} alt="pr10" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Product Roadmap</h1>
                <p className="font-m app-font-rg pt-2">
                  Based on the goals I defined, I started making decisions on
                  what solutions we would prioritize and implement in order to
                  successfully and effectively meet those goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p9} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Sitemap</h1>
                <p className="font-m app-font-rg pt-2">
                  In order to keep the information architecture structure of the
                  entity management in the right manner, I have visited the
                  portal and listed down the major painpoints and reconstructe
                  the inforation architecture based on user interviews. Based on
                  the inputs received and after segregating the different tasks
                  which helped me to further iterate the prototypes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p10} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Wireframes</h1>
                <p className="font-m app-font-rg pt-2">
                  Taking the Low-fi wireframe sketches, I digitized them on
                  Sketch and added just enough information for users to be able
                  to navigate through other entity pages and complete tasks, I
                  would present to them during usability testing for initial
                  level of sprint. These mid-fidelity wireframes would help my
                  focus on what needed to be improved in terms of the
                  functionality of my design. I also created tablet and mobile
                  versions to make sure that the design is responsive and
                  effective across the different device screens that users would
                  be accessing the website on.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light w-100">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p11} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="app-bg-white w-100 py-4">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <img src={p12} className="img-fluid" alt="pr" />
          </div>
        </div>
        <div className="app-bg-blue w-100 py-5">
          <div className="container py-5" style={{ maxWidth: "1112px" }}>
            <h1 className="font-xxl app-font-bold">New Visual Design</h1>
            <div className="col-11 mx-auto d-flex flex-row flex-wrap">
              <div className="col-6 p-4">
                <img className="img-fluid" src={p13} alt="pr13" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p14} alt="pr14" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p15} alt="pr15" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p16} alt="pr16" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p17} alt="pr17" />
              </div>
              <div className="col-6 p-4">
                <img className="img-fluid" src={p18} alt="pr18" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-100">
          <div className="container pt-5" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-3">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">
                  Conduct Usability Testing
                </h1>
                <p className="font-m app-font-rg py-2">
                  I then conducted both in-person and remote usability testing
                  with 5 participants, and created transcripts for each
                  participant based on my observation of their interaction with
                  the prototype. I jot down their mistakes, slips, and
                  confusions they expressed in the process. This transcript is a
                  perfect raw material for summarizing the patterns of user’s
                  interaction with the prototype.
                </p>
                <h1 className="font-xl app-font-bold pt-3">
                  Design objectives:
                </h1>
                <p className="font-m app-font-rg">
                  Test the ease of navigation Entity pages.
                </p>
                <p className="font-m app-font-rg">
                  Test how well the interface promotes a feeling of calm and
                  trust for the user.
                </p>
                <p className="font-m app-font-rg">
                  Test how easily the user can complete an entity documents to
                  submit through online.
                </p>
                <p className="font-m app-font-rg">
                  Assess whether components are well placed to promote easy task
                  completion and site navigation.
                </p>
                <p className="font-m app-font-rg">
                  I designed my user flow so that users could move in a few
                  different ways from the Dashbaord all the way through the
                  quote and contracts.
                </p>
                <p className="font-m app-font-rg">
                  I posted my usability test link clicable prototye on userzoom,
                  with the request for customer and CT service team members to
                  test both internal and external view.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bg-white w-100">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="col-11 mx-auto pb-5">
              <div className="col-12">
                <h1 className="font-xxl app-font-bold">Outcome</h1>
                <p className="font-m app-font-rg">
                  - Usability Test Plan and Results The purpose of my usability
                  test was to assess the learnability for new users interacting
                  with the Health Pal application for the first time on a mobile
                  iOS device.
                </p>
                <p className="font-m app-font-rg">
                  - I observed and measured the success rate of 6 participants
                  completing 2 task attempts each.
                </p>
                <p className="font-m app-font-rg">
                  - I documented whether the participants understood my product,
                  the value of my three core features, and how to achieve
                  essential functions.
                </p>
                <p className="font-m app-font-rg">
                  - Overall users enjoyed the experience of being able to talk
                  to a Health Pal and have the Health Pal book appointments for
                  them, rather than going down the rabbit hole of using their
                  health insurances web portal to make appointments.
                </p>
                <p className="font-m app-font-rg">
                  - During my moderated usability test I conducted with 6
                  participants, I gathered some great insights to make my
                  product more user friendly with future iterations.
                </p>
                <p className="font-m app-font-rg">
                  - Health Pal has gone through 4 design iterations with
                  usability testing, preference testing, mentor feedback, and
                  senior design and product manager feedback.
                </p>
                <p className="font-m app-font-rg">
                  - That being said, there is still more room to improve and I
                  am just getting my product off the ground. Submission of
                  detailed client assessment forms in order to get clients
                  paired up with practitioners that fit their needs.
                </p>
                <p className="font-m app-font-rg">
                  - Client ability to save “Favourite” practitioners in order to
                  allow quick future bookings. The final and future form of this
                  web app will include a Corporate portal.
                </p>
                <p className="font-m app-font-rg">
                  - The idea is that employers would include the site’s services
                  as part of their Benefits package to employee
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Iqvia;
