import React from "react";

import f1 from "../../../assets/svg/lk.svg";
import f2 from "../../../assets/svg/dr.svg";
import f3 from "../../../assets/svg/bh.svg";

function Footer() {

  return (
    <>
      <div className="col-12 w-100 py-2 bg-light">
        <div className="container">
          <div className="col-12 py-1 d-flex flex-row justify-content-between flex-nowrap align-items-center">
            <div className="col-6">
              <h3 className="font-s app-font-rg">
                Copyrights @ 2021 - Jona Frank. All rights reserved.
              </h3>
            </div>
            <div className="col-2 d-flex justify-content-around">
              <span
                className="d-inline-block crsr_pntr"
                onClick={() => window.open("https://dribbble.com/JonaFrank")} title="Dribble"
              >
                <img src={f2} alt="social_media_dribble" width="20px" />
              </span>
              <span className="d-inline-block crsr_pntr" title="Behance">
                <img src={f3} alt="social_media_behance" width="20px" onClick={() => window.open("https://www.behance.net/jonafrankjk")} />
              </span>
              <span className="d-inline-block crsr_pntr" title="LinkedIn" onClick={() => window.open("https://www.linkedin.com/in/jona-frank-570151ab/")}>
                <img src={f1} alt="social_media_linkedin" width="20px" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
