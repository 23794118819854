import React from "react";
import { useNavigate } from "react-router-dom";

import "./home.scss";
import "../../../index.css";
import sv1 from "../../../assets/images/3.svg";
import PasswordComp from "../../services/toaster-service/password/password-comp";
import { ToasterService } from "../../services/toaster-service/toaster";

function HomePage() {
  const history = useNavigate();

  const redirectToPage = (URI) => {
    ToasterService.SetPassword((val) => {
      if (val) {
        history(URI);
      }
    });
  }

  return (
    <>
      <div>
        <div
          className="w-100 homeBg"
          style={{ backgroundColor: "rgb(241 179 82 / 11%)" }}
        >
          <div className="container h-100" style={{ maxWidth: "1112px" }}>
            <div className="col-12 d-flex flex-row align-items-center h-100 justify-content-center">
              <div className="col-6">
                <h1 className="app-font-bold title_font">
                  {" "}
                  Hi, I 'm Jona Frank:
                </h1>
                <h1 className="m-0 font-xxxl"> Product designer </h1>
                <p className="mt-4 w-80 font-l app-font-rg">
                  I create data - driven solutions that elevate human
                  experiences and perfectly balance user and business needs to
                  create delightful products.
                </p>
              </div>
              <div className={"col-6 text-end"}>
                <img src={sv1} alt="textimg" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white d-flex justify-content-center align-items-center col-12 pt-5"
        >
          <div className="container" style={{ maxWidth: "1112px" }}>
            <div className="w-100 d-flex flex-row justify-content-center align-items-stretch pt-3 h-100-vh">
              <div className={"col-6 section-1 position-relative"}>
                <div className="floatingImg img-1"></div>
                <div className="floatingImg img-2"></div>
              </div>
              <div
                className={"col-6 d-flex flex-column justify-content-center"}
              >
                <div className="col-10 mx-auto">
                  <h1 className="font-xxl app-font-bold">Entity Management</h1>
                  <p className="mt-3 font-m app-font-rg">
                    Centralized platform that enables the efficient and secure
                    handling of all your legal entity data—at the local, state,
                    national and global level.
                  </p>
                  <div className="w-100 col-12 py-4 text-start">
                    <button
                      className="btn btn-outline-dark cstm_btn_dark"
                      value="READ CASE STUDY"
                      onClick={() => redirectToPage("/ent-mng")}
                    >
                      <span>READ CASE STUDY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-stretch pt-3 h-100-vh">
              <div
                className={"col-6 d-flex flex-column justify-content-center"}
              >
                <div className="col-10">
                  <h1 className="font-xxl app-font-bold">Design System</h1>
                  <p className="mt-3 font-m app-font-rg">
                    Wolterskluwer Design System rewamping collection of reusable
                    components, guided by clear standards, a library of visual
                    style, components, and other concerns documented.
                  </p>
                  <div className="w-100 col-12 py-4">
                    <button
                      className="btn btn-outline-dark cstm_btn_dark"
                      value="READ CASE STUDY"
                      onClick={() => redirectToPage("/ds")}
                    >
                      <span>READ CASE STUDY</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className={"col-6 section-2 position-relative"}>
                <div className="floatingImg img-1"></div>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-stretch pt-3 h-100-vh">
              <div className={"col-6 section-3 position-relative"}>
                <div className="floatingImg img-1"></div>
                <div className="floatingImg img-2"></div>
              </div>
              <div
                className={"col-6 d-flex flex-column justify-content-center"}
              >
                <div className="col-10 mx-auto">
                  <h1 className="font-xxl app-font-bold">Digital Classroom</h1>
                  <p className="mt-3 font-m app-font-rg">
                    Digital classroom (iOS, Android,Web).
                  </p>
                  <div className="w-100 col-12 py-4">
                    <button
                      className="btn btn-outline-dark cstm_btn_dark"
                      value="READ CASE STUDY"
                      onClick={() => redirectToPage("/dc")}
                    >
                      <span>READ CASE STUDY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-stretch pt-3 h-100-vh">
              <div
                className={"col-6 d-flex flex-column justify-content-center"}
              >
                <div className="col-10 mx-auto">
                  <h1 className="font-xxl app-font-bold">IQVIA</h1>
                  <p className="mt-3 font-m app-font-rg">
                  IQVIA Decentralized Trials deliver purpose-built clinical services and industry-leading technologies that engage the right patients wherever they are. Our onsite and community-based solutions deliver flexible patient and site strategies for every therapeutic area.
                  </p>
                  <div className="w-100 col-12 py-4 text-start">
                    <button
                      className="btn btn-outline-dark cstm_btn_dark"
                      value="READ CASE STUDY"
                      onClick={() => redirectToPage("/iqvia")}
                    >
                      <span>READ CASE STUDY</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className={"col-6 section-4 position-relative"}>
                <div className="floatingImg img-1"></div>
                <div className="floatingImg img-2"></div>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-stretch pt-3 h-100-vh">
            <div className={"col-6 section-5 position-relative"}>
                <div className="floatingImg img-1"></div>
                <div className="floatingImg img-2"></div>
              </div>
              <div
                className={"col-6 d-flex flex-column justify-content-center"}
              >
                <div className="col-10 mx-auto">
                  <h1 className="font-xxl app-font-bold">Avigna</h1>
                  <p className="mt-3 font-m app-font-rg">
                    Responsive Real estate website design.
                  </p>
                  <div className="w-100 col-12 py-4">
                    <button
                      className="btn btn-outline-dark cstm_btn_dark"
                      value="READ CASE STUDY"
                      onClick={() => redirectToPage("/avigna")}
                    >
                      <span>READ CASE STUDY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white d-flex justify-content-center align-items-center col-12 py-5">
          <div className="container" style={{ maxWidth: "1112px" }}>
            <h3 className="text-dark text-center font-xxxl app-font-bold">
              The 4 step process to
            </h3>
            <h3 className="text-center font-xxxl app-font-bold app-color-dark-green mb-5">
              make your project real.
            </h3>
            <div className="col-7 mx-auto p-5 bg-light rounded position-relative">
              <div className="col-10 mx-auto srch_bg">
                <div className="w-100">
                  <h4 className="text-secondary pb-5 font-xxl">
                    01/ Discovery
                  </h4>
                  <p className="text-secondary font-m app-font-rg">
                    Every project starts with a discovery session where we get
                    to know each other and conduct in-deth research.
                  </p>
                  <p className="text-secondary font-m app-font-rg mt-4">
                    We identify the business problems, its objectivesm vision
                    and what makes your brand different. We also study the
                    market, analyze competitors and figure out user's needs,
                    wants and pain points.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
export default HomePage;
