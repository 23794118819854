import React from "react";
import "./ag.scss";
import ag1 from "../../../../../assets/images/ag/2.png";
import ag2 from "../../../../../assets/images/ag/3.png";
import ag3 from "../../../../../assets/images/ag/4.png";
import ag4 from "../../../../../assets/images/ag/5.png";
import ag5 from "../../../../../assets/images/ag/6.png";
// import ag6 from "../../../../../assets/images/ag/6.png";
import ag7 from "../../../../../assets/images/ag/7.png";
import ag8 from "../../../../../assets/images/ag/8.png";
import ag9 from "../../../../../assets/images/ag/9.png";
import ag10 from "../../../../../assets/images/ag/10.png";

import ag11 from "../../../../../assets/images/ag/11.png";
import ag12 from "../../../../../assets/images/ag/12.png";
import ag13 from "../../../../../assets/images/ag/13.png";
import ag14 from "../../../../../assets/images/ag/14.png";
import ag15 from "../../../../../assets/images/ag/15.png";

function AvignaProperties(props) {
  return (
    <>
      <div className="w-100 agBg">
        <div className="container h-100">
          <div className="col-4 px-2 h-100">
            <div className="d-flex align-items-center h-100 w-100 pt-5">
              <span className="floating_text text-white d-inline-block position-relative pt-2 font-xxxxl app-font-bold">
                Avigna Properties
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-100 pt-5 pb-3">
        <div className="container py-3">
          <div className="w-100 d-flex flex-row">
            <div className="col-4 px-2 d-flex align-items-start">
              <div className="d-flex h-100 w-100 pt-5">
                <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                  Overview
                </span>
              </div>
            </div>
            <div className="col-8 px-2">
              <div className="w-100 pb-2">
                <h4 className="font-xxl app-font-bold mb-3">The Client</h4>
                <p className="pb-3 font-m app-font-rg">
                  Avigna is a marketplace for selling individual house and flats
                  for family rental based and buying as well and it aims to
                  build relationships with people the moment they relocate to
                  the city to their long term housing decisions. The company has
                  been designing their website to make user friendly. I was
                  tasked to design a new website and admin portal dashboard for
                  company to connect them with customers.
                </p>
              </div>
              <div className="w-100 pb-2">
                <h4 className="font-xxl app-font-bold mb-3">User Goals</h4>
                <p className="pb-3 font-m app-font-rg">
                  User Goals Avigna need a way to connect with customer that are
                  financially capable and serious about purchasing a home as
                  well rental based stay.
                </p>
              </div>
              <div className="w-100 pb-2">
                <h4 className="font-xxl app-font-bold mb-3">Business Goals</h4>
                <p className="pb-3 font-m app-font-rg">
                  Avigna needs a way to connect experienced, trustworthy
                  customer who possess comprehensive knowledge about the city
                  they live in, with rental customer who working in IT
                  professional mostly that have the financial capability and
                  goal of purchasing a home nearby their working place.
                </p>
              </div>
              <div className="w-100 pb-2">
                <h4 className="font-xxl app-font-bold mb-3">Process</h4>
                <p className="pb-3 font-m app-font-rg">
                  This was a 2 months project that was divided into design and
                  development phases. During the first phase we conducted
                  research to define the user problem and the solution for their
                  website. After ideation process I have designed the initial
                  Low-fi prototype, tested with user, iterated and produced
                  comps. During this phase focused on designing the dashboard
                  for avigna admin portal for their internal use to track
                  customer details for record.
                </p>
              </div>
              <div className="w-100 pb-3">
                <img className="img-fluid" src={ag1} alt="section-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="container">
          <div className="col-8 ml-auto spacer_bottom"></div>
        </div>
      </div>
      <div className="w-100 bg-white">
        <div className="container">
          <div className="row">
            <div className="w-100 d-flex flex-row">
              <div className="col-4 px-2 d-flex align-items-start">
                <div className="d-flex h-100 w-100 pt-5">
                  <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                    Research
                  </span>
                </div>
              </div>
              <div className="col-8 px-2">
                <div className="w-100 pb-3">
                  <p className="pb-3 font-m app-font-rg">
                    During the research phase, I sought to understand the
                    current state of the Real Estate landscape. My intention for
                    the research phase was to uncover the needs and frustrations
                    of the target user to better understand how to appeal to
                    them.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">
                    Competitive Analysis
                  </h4>
                  <p className="pb-3 font-m app-font-rg">
                    User Goals Avigna need a way to connect with customer that
                    are financially capable and serious about purchasing a home
                    as well rental based stay.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <img className="img-fluid" src={ag2} alt="section-2" />
                </div>
                <div className="w-100 pb-3">
                  <p className="pb-3 font-m app-font-rg">
                    I started with the user and market research to understand
                    and analysed online market places for buying house and
                    rental to see if there are any builders that provides to buy
                    individual house and rental house who are interested in
                    buying a home. I identified some main builders selling
                    individual “Mahendra city”, “TVH”, “Om sakthy” as a direct
                    competitor, as it provides a way to buying individual house
                    and rental based stay. My focus was on-boarding a website of
                    other builders so I compared listing landing pages about,
                    Gallery, Benefits pages of major builders companies.
                    Although these companies are market leaders in providing
                    leads I found that there is no way to distinguish quality
                    leads on their platforms. I decided that Avigna should
                    differentiate from in a way that it screens other builders
                    landing page. After organizing our research, we were able to
                    figure out what features to focus on implementing for our
                    minimum viable product(High/Low - Value/Effort).
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <p className="pb-3 font-m app-font-rg">
                    cooperation between real market builders is frowned upon
                    sometimes. More experienced builders have more listings than
                    clients, search is frustrating and time-consuming for
                    customers, and while expired listings generate leads, they
                    also waste a builders time.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">
                    User Interviews
                  </h4>
                  <p className="pb-3 font-m app-font-rg">
                    I used the discussion guide to conduct interviews with
                    customers who will ready to buy an individual house nearby
                    their work place. Mostly IT professional who near by located
                    in Mahendara city age between(25 - 55). Affinity map
                    technique is used to understand the relationship between our
                    findings and define key takeaways from all interviews. In
                    order to design a website with a great user experience, I
                    had to first identify the needs of the target audience. To
                    better understand the perspective of users, I had speak with
                    them directly and ask them about their buying experiences
                    and habits.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">Persona</h4>
                  <p className="pb-3 font-m app-font-rg">
                    Narrowing down our findings from user interviews I have
                    created a persona, , that embodied the pain points,
                    characteristics and motivations of our target user.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <img className="img-fluid" src={ag3} alt="section-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="container">
          <div className="col-8 ml-auto spacer_bottom"></div>
        </div>
      </div>
      <div className="w-100 bg-white">
        <div className="container">
          <div className="row">
            <div className="w-100 d-flex flex-row">
              <div className="col-4 px-2 d-flex align-items-start">
                <div className="d-flex h-100 w-100 pt-5">
                  <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                    Define & Ideate
                  </span>
                </div>
              </div>
              <div className="col-8 px-2">
                <div className="w-100 pb-3">
                  <p className="pb-3 font-m app-font-rg">
                    Now that I had empathized with target users and identified
                    their needs, I needed to define the solution. I reflected on
                    the business goals, user goals, and technical considerations
                    to find a happy medium for my client. Once I had identified
                    common goals, I could decide what product features were
                    necessary for the website.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">
                    Affinity Diagram
                  </h4>
                  <p className="pb-3 font-m app-font-rg">
                    We then created an affinity diagram to make sense of all of
                    it. An affinity diagram is helpful for me to categorize
                    information into meaningful sections to help analyze data
                    better. I created an affinity map to organize and synthesize
                    the responses and observations I captured using testing. I
                    uncovered insights, which I reconstructed into a list of
                    recommendations. I organized these by priority level (high
                    to low) as a way of determine what needed to be changed
                    immediately and what could be saved for later.
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <img className="img-fluid" src={ag4} alt="section-4" />
                </div>
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">
                    Site flow and User flow
                  </h4>
                  <p className="pb-3 font-m app-font-rg">
                    sers emphasised the importance of “trust” between the user
                    and the builders in the interviews and they mentioned that
                    it is hard to create trust, so there needs to be some level
                    of trust upon introduction. Once I had an idea of the
                    features that we wanted to incorporate in the website, we
                    used a user flow to focus on what needed to accomplish and
                    how to deliver that in the most effective manner possible.
                    This lead to better user experiences as it placed heart of
                    the design process. I had design for admin portal for
                    internal and customer usage. Based on response trends, I
                    created a site
                  </p>
                </div>
                <div className="w-100 pb-3">
                  <img className="img-fluid" src={ag5} alt="section-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="container">
          <div className="col-8 ml-auto spacer_bottom"></div>
        </div>
      </div>
      <div className="w-100 bg-white py-5">
        <div className="container">
          <div className="row">
            <div className="w-100 d-flex flex-row">
              <div className="col-4 px-2 d-flex align-items-start">
                <div className="d-flex h-100 w-100 pt-5">
                  <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                    Design & Test
                  </span>
                </div>
              </div>
              <div className="col-8 px-2">
                <div className="w-100 pb-3">
                  <h4 className="font-xxl app-font-bold mb-3">
                    Low-Fidelity Wireframes
                  </h4>
                  <p className="pb-2 font-m app-font-rg">
                    We started off by making a paper prototype to rapidly test
                    different variations of key features. I began the process of
                    wireframing with sketches of variations of the website
                    landing page. During this process, I thought about how the
                    layout and content could be structured to satisfy user and
                    business goals in a technically feasbile way. The sketch I
                    chose served as a guide for my digital wireframes.
                  </p>
                </div>
                <div className="w-100 pb-4">
                  <img className="img-fluid" src={ag15} alt="section-4" />
                </div>
                <div className="w-100 pb-2">
                  <p className="pb-2 font-m app-font-rg">
                    At this point in the design process, I needed to think about
                    how I wanted the site to look on different screen sizes. It
                    was essential that the website would present as well on
                    tablet and mobile devices as it did in desktop size. I
                    created responsive wireframes for Mirror's key pages and
                    began to flesh them out.
                  </p>
                </div>
                <div className="w-100 pb-2">
                  <h4 className="font-xxl app-font-bold mb-2">
                    High Fidelity Wireffames - Desktop view
                  </h4>
                  <p className="pb-2 font-m app-font-rg">
                    After several iterations of my low-fidelity prototype, I
                    fleshed out the details of each page with images,
                    typography, navigation menus, etc.
                  </p>
                </div>
                <div className="w-100 pb-2">
                  <h4 className="font-xxl app-font-bold mb-2">
                    Design Challenges
                  </h4>
                  <p className="pb-2 font-m app-font-rg">
                    * Determining the required information and choosing right
                    titles for form fields were important to create a process
                    that meets the expectations of admin portal. It was a
                    challenge for me, so I tried to learn as much about the
                    industry key terms form the real estate industry.
                  </p>
                  <p className="pb-2 font-m app-font-rg">
                    * Uploading latest Images and Maintaining Huge numbers of
                    customers data in single page and Legal
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 py-5">
        <img className="img-fluid" src={ag7} alt="section-7" />
      </div>
      <div className="w-100 py-5">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img className="img-fluid" src={ag8} alt="section-8" />
            </div>
            <div className="col-4">
              <img className="img-fluid" src={ag9} alt="section-9" />
            </div>
            <div className="col-4">
              <img className="img-fluid" src={ag10} alt="section-10" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 py-5">
        <div className="container">
          <img className="img-fluid" src={ag11} alt="section-11" />
        </div>
      </div>
      <div className="w-100 py-5">
        <div className="container">
          <img className="img-fluid" src={ag12} alt="section-12" />
        </div>
      </div>
      <div className="w-100 py-5">
        <div className="container">
          <img className="img-fluid" src={ag13} alt="section-13" />
        </div>
      </div>
      <div className="w-100 py-5">
        <div className="container">
          <img className="img-fluid" src={ag14} alt="section-14" />
        </div>
      </div>
      <div className="bg-white w-100 pt-5 pb-3">
        <div className="container py-3">
          <div className="w-100 d-flex flex-row">
            <div className="col-4 px-2 d-flex align-items-start">
              <div className="d-flex h-100 w-100">
                <span className="floating_text d-inline-block position-relative pt-2 font-xxxl app-font-bold">
                  Outcomes
                </span>
              </div>
            </div>
            <div className="col-8 px-2">
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">
                  Usability Testing
                </h4>
                <p className="pb-2 font-m app-font-rg">
                  I have tested the initial prototype with both builders and
                  professionals working in other field(Users). I conducted 2 one
                  for admin and other for user usability tests. created a chart
                  to identify the repeated observations from tests.
                </p>
              </div>
              <div className="w-100 pb-2">
                <h4 className="font-l app-font-bold mb-2">
                  Key findings were:
                </h4>
                <p className="font-m app-font-rg">
                  * Landing page needs to be short
                </p>
                <p className="font-m app-font-rg">
                  * Adding more pictures and Contact chat information needs to
                  update
                </p>
                <p className="font-m app-font-rg">
                  * They couldn’t understand what type of relevant information
                  were required.
                </p>
                <p className="font-m app-font-rg">
                  * Application process was found straightforward. The length
                  was mentioned as fine.
                </p>
                <p className="font-m app-font-rg">
                  * They said that providing certain personal information in a
                  profile page could create preconceived judgment among buyers.
                </p>
                <p className="font-m app-font-rg">
                  * Admin dashboard for user has to enhance with more details.
                </p>
              </div>
              <div className="w-100 pb-3">
                <h4 className="font-xxl app-font-bold mb-3">SOLUTION</h4>
                <p className="pb-3 font-l app-font-rg">What We Achieved!!!</p>
                <p className="pb-3 font-m app-font-rg">
                  <strong>For Builders:</strong> A digital platform which lets
                  them control how they are portrayed as a builders and provides
                  them with a way to connect with customers who are financially
                  capable and serious about purchasing a home and rental stay.
                </p>
                <p className="pb-3 font-m app-font-rg">
                  <strong>For Customers:</strong> A new living experience which
                  helps to find individual house with budget based Working
                  people, trustworthy builders partners who possess
                  comprehensive knowledge about the city.
                </p>
                <p className="pb-3 font-m app-font-rg">
                  Overall, i have created a platform aimed to reduce time
                  wastage for commercial buying experience for users. The final
                  product matched my client expectations, and fulfilled the
                  business goals, user goals, and project goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvignaProperties;
