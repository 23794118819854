import React, { useState } from "react";
import "./password-comp.scss";
import { FaLock } from "react-icons/fa";
import { BsX } from "react-icons/bs";

function PasswordComp({close, onExit}) {
  const [pwdVal, setPwdVal] = useState("");

  const clsEvent = (e) => {
    e.preventDefault();
    close(pwdVal);
  };

  return (
    <div className="password-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-5 mx-auto">
            <div className="tile">
              <div className="lockSection">
                <span className="lockIcon">
                  <FaLock size="17" />
                </span>
                <span className="close" onClick={onExit}>
                  <BsX color="#5d5d5d" size="32" title="Close" />
                </span>
              </div>
              <h3>This content is password protected.</h3>
              <form name="pswrdForm" onSubmit={(e) => clsEvent(e)}>
                <div className="form-group">
                  <div className="input-group">
                    {!pwdVal && (
                      <label className="inp-label">Enter Password</label>
                    )}
                    <input
                      type="password"
                      className="form-control"
                      name="pwd"
                      id="pwd"
                      onChange={(e) => setPwdVal(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordComp;
